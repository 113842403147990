.no-need-auth[data-v-3deb0927] {
  display: inline-block;
  font-weight: 600;
  color: #67c23a;
  cursor: pointer;
}
.go-auth-box[data-v-3deb0927] {
  display: inline-block;
}
.go-auth-box .go-auth[data-v-3deb0927] {
  font-weight: 600;
  color: #afacac;
  cursor: pointer;
}
.had-auth[data-v-3deb0927] {
  font-weight: 600;
  color: var(--themeColor, #17a2b8);
  cursor: pointer;
}
.go-setting[data-v-3deb0927] {
  display: inline-block;
}
.iconfont[data-v-3deb0927] {
  font-size: 15px;
}
.note_content[data-v-4bef2382] {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: flex-end;
  padding-bottom: 20px;
}
.note_content img[data-v-4bef2382] {
  width: 300px;
  margin-top: 5px;
}
.time[data-v-4bef2382] {
  font-size: 18px;
}
.note[data-v-4bef2382] {
  font-size: 14px;
  margin-top: 5px;
}
.archives_content[data-v-4bef2382] {
  width: 90%;
  margin: 0 auto;
}
.archives_box[data-v-4bef2382] {
  width: 90%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}
.archives_up[data-v-4bef2382] {
  width: 96%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}
.file[data-v-4bef2382] {
  padding: 10px 0;
  cursor: pointer;
  color: var(--themeColor, #17a2b8);
}
.div[data-v-4bef2382] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.active[data-v-4bef2382] {
  display: none;
}
[data-v-4bef2382] .el-drawer__header {
  margin-bottom: 0px;
}
.bigimg[data-v-4bef2382] {
  cursor: pointer;
}
.bigimg input[data-v-4bef2382] {
  width: 98px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 35px;
}
.pic[data-v-4bef2382] {
  display: flex;
}
.del_btn[data-v-4bef2382] {
  position: relative;
  top: 0;
  padding-right: 10px;
  color: red;
  cursor: pointer;
}
.div_p[data-v-4bef2382] {
  line-height: 28px;
  border-bottom: 1px solid #efefef;
  height: 28px;
}
.div_p p[data-v-4bef2382] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  cursor: pointer;
}
.div_p[data-v-4bef2382]:last-child {
  border-bottom: none;
}
.text_initial[data-v-4bef2382] {
  white-space: initial;
}
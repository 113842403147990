.ai_home[data-v-13e70a3d] {
  width: 100%;
  height: 100%;
  font-size: 14px;
  background-color: #f0f3f8;
  padding: 30px 45px;
}
.select_box[data-v-13e70a3d] {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.each_content[data-v-13e70a3d] {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0px 2px 4px #cbcbcb;
}
.com_content[data-v-13e70a3d] {
  display: flex;
  align-items: center;
  padding: 20px;
}
.com_content img[data-v-13e70a3d] {
  width: 35px;
  height: 35px;
}
.com_content .my_com[data-v-13e70a3d] {
  text-align: center;
  margin-left: 10px;
}
.com_content .com_right_fwz[data-v-13e70a3d]::before {
  content: "";
  background: #17a2b8;
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: relative;
  margin-right: 3px;
  bottom: 2px;
}
.com_content .com_right_dx[data-v-13e70a3d]::before {
  content: "";
  background: #ff3d3d;
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: relative;
  margin-right: 3px;
  bottom: 2px;
}
.com_content .com_right_count[data-v-13e70a3d] {
  font-size: 13px;
  margin-top: 6px;
  margin-left: 9px;
}
.my_com_count[data-v-13e70a3d] {
  font-size: 14px;
  font-weight: 700;
}
.my_com_title[data-v-13e70a3d] {
  font-size: 13px;
  color: #666;
}
.fgx[data-v-13e70a3d] {
  margin: 0 8%;
  border-left: 1px solid #c7c5c5;
  width: 1px;
  height: 32px;
}
.statistics_content[data-v-13e70a3d] {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.statistics_each[data-v-13e70a3d] {
  width: 49%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #f2f5fa;
  padding: 15px 10px;
}
.statistics_each img[data-v-13e70a3d] {
  width: 44px;
  height: 44px;
}
.record[data-v-13e70a3d] {
  font-size: 13px;
  margin-left: 10px;
  margin-top: 2px;
}
.record[data-v-13e70a3d]::before {
  content: "";
  background: #17a2b8;
  display: inline-block;
  width: 4px;
  height: 17px;
  position: relative;
  margin-right: 5px;
  top: 4px;
}
.echarts_box[data-v-13e70a3d] {
  display: flex;
  margin: 10px;
  border-radius: 4px;
  align-items: center;
  border: 1px solid #e5e5e5;
}
.echarts_box .echarts_each_bar[data-v-13e70a3d] {
  width: 65%;
}
.echarts_box .echarts_each_pie[data-v-13e70a3d] {
  margin-left: 20px;
}
.tax_title[data-v-13e70a3d] {
  padding: 10px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
}
.tax_title img[data-v-13e70a3d] {
  width: 24px;
  height: 24px;
}
.tax_title .tax_title_text[data-v-13e70a3d] {
  font-size: 14px;
  font-weight: 700;
  color: #2c3e50;
  margin-left: 4px;
}
.tax_tab[data-v-13e70a3d] {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tax_tab .tax_tab_each[data-v-13e70a3d] {
  width: 49%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 15px 10px;
  background-image: url(../img/mb_bg.9ef7fd7e.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.tax_tab .tax_tab_each img[data-v-13e70a3d] {
  width: 44px;
  height: 44px;
}
.tax_tab .tax_tab_each_right[data-v-13e70a3d] {
  background-image: url(../img/sq_bg.7a7d9591.png);
}
.tax_tab .tax_tab_each_hd[data-v-13e70a3d] {
  background-image: url(../img/hd_bg.4d725146.png);
}
.tax_sb[data-v-13e70a3d] {
  margin: 10px;
  padding: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}
.tax_sb_content[data-v-13e70a3d] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.shebao_sb[data-v-13e70a3d] {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.shebao_each[data-v-13e70a3d] {
  width: 49%;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  padding: 10px;
}
.back_btn[data-v-13e70a3d] {
  text-align: right;
  margin-bottom: 10px;
  cursor: pointer;
  color: #17a2b8;
}
.back_btn .icon-ai207[data-v-13e70a3d] {
  margin-right: 4px;
}
.time_color[data-v-13e70a3d] {
  font-size: 16px;
  color: var(--themeColor, #17a2b8);
}
.icon-guangbo[data-v-13e70a3d] {
  color: var(--themeColor, #17a2b8);
  margin-right: 10px;
}
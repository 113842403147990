
.zz {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0);
  z-index: 999;
}
/* .message_content .el-tabs__item{
  height: 24px !important;
} */
.message_content .el-tabs__nav-wrap::after {
  border-bottom: none !important;
}

.left-big[data-v-63d2dca9] {
  width: 95%;
  float: left;
  display: flex;
}
.left-big span[data-v-63d2dca9] {
  font-size: 14px;
}
.spanName[data-v-63d2dca9] {
  padding: 0 20px;
}
.left-big[data-v-63d2dca9]::-webkit-scrollbar {
  width: 4px;
}
.left-big[data-v-63d2dca9]::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(100, 100, 100, 0.2);
}
.el-menu-item[data-v-63d2dca9]:not(.is-disabled):hover {
  border-bottom: 4px solid var(--themeColorMenuBottom, #17a2b8) !important;
  background-color: var(--themeColorMenuBg, #384e63) !important;
}
.el-menu-item[data-v-63d2dca9]:not(.is-disabled):focus {
  border-bottom: 4px solid var(--themeColorMenuBottom, #17a2b8) !important;
  background-color: var(--themeColorMenuBg, #384e63) !important;
}
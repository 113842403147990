/* @font-face {
  font-family: 'Mysh';
  src: url("./msyh.ttf") format('truetype');
  src: url('./msyh.woff') format('woff');
  src: url('./msyh.woff2') format('woff2');
} */

/* @font-face {
  font-family: 'msyh';
  src: url("./msyh.ttf") format('truetype');
  src: url('MicrosoftYaHei.woff2') format('woff2'),
      url('MicrosoftYaHei.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
} */
@font-face {
  font-family: 'Mysh';
  src: url(../fonts/Mysh.6ed98fea.woff2) format('woff2'),
        url(../fonts/Mysh.6d93e3c5.woff) format('woff'),
        url(../fonts/Mysh.e70f65c2.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.el-select[data-v-05f9e268] {
  margin-left: 0px;
  margin-right: 0px;
}
.common_dialog_box .common_title[data-v-05f9e268] {
  text-align: left;
}
.item_one[data-v-05f9e268] {
  display: flex;
  flex-flow: wrap;
}
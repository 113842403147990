.app-main[data-v-481040fe] {
  width: calc(100% - 12px);
  min-height: calc(100vh - 96px);
  background: #fff;
  margin-top: 5px;
  margin-left: 5px;
  overflow: auto;
  float: left;
}
.message_style[data-v-481040fe] {
  position: absolute;
  right: 20px;
  bottom: 20px;
  background: linear-gradient(to right, #d8e7fd, #f6faff);
  border-radius: 3px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 14px;
  border: 1px solid #9fbce7;
  z-index: 2000;
}
.message_style .message_right[data-v-481040fe] {
  margin-left: 80px;
}
.mes_content[data-v-481040fe] {
  position: absolute;
  right: 0px;
  bottom: 40%;
  z-index: 2000;
  cursor: pointer;
  text-align: -webkit-right;
  text-align: -moz-right;
}
.zxzx[data-v-481040fe] {
  width: 24px;
  text-align: center;
  background: linear-gradient(to bottom, #1062fd, #59a6fc);
  color: #fff;
  border-radius: 18px;
  padding: 8px 0;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
}
.zxzx_text[data-v-481040fe] {
  width: 20px;
  margin: auto;
  display: block;
  font-size: 13px;
  line-height: 16px;
}
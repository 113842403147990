.el-sub-menu .el-menu-item[data-v-d60368d6] {
  padding-left: 15px !important;
  min-width: 20px;
  line-height: 35px !important;
  height: 35px !important;
  font-weight: 500;
  font-size: 16px;
}
.el-menu-item[data-v-d60368d6] {
  line-height: 35px !important;
  height: 35px !important;
  padding-left: 15px !important;
}
.el-sub-menu[data-v-d60368d6] .el-sub-menu__icon-arrow {
  display: none !important;
}
.el-menu-item.is-active[data-v-d60368d6] {
  background-color: var(--themeColorLeftMenu, #ecf7f9) !important;
  color: #333 !important;
  padding-left: 15px !important;
}
.el-menu-item.is-active[data-v-d60368d6]::before {
  z-index: 1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-left: 5px solid var(--themeColor, #17a2b8);
  width: 100px;
  height: 100%;
  box-sizing: border-box;
}
.el-menu[data-v-d60368d6] {
  border-right: 0 !important;
}
.menu_bg[data-v-d60368d6] {
  width: 130px;
  height: 30px;
  background-image: var(--menuBg);
  background-color: #fff;
  background-size: 100%;
  background-repeat: no-repeat;
}
.el-menu-item [class^=el-icon][data-v-d60368d6] {
  margin-right: 0;
}
[data-v-ed8ee6e4] .el-badge__content.is-fixed {
  top: 11px;
  right: 2px;
}
.el-tag[data-v-ed8ee6e4] {
  color: #fff !important;
}
.load_list[data-v-ed8ee6e4] {
  width: 100%;
  margin: 0 auto;
  margin-top: 40px;
}
.load_list li[data-v-ed8ee6e4] {
  border-bottom: 1px solid #eee;
}
.load_list p[data-v-ed8ee6e4] {
  font-size: 14px;
  color: #333;
  font-weight: normal;
  position: relative;
  padding-left: 15px;
}
.load_list p[data-v-ed8ee6e4]:before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--themeColor, #17a2b8);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.message_list[data-v-ed8ee6e4] {
  width: 520px;
  height: calc(100vh - 56px);
  background: #fff;
  border: 1px solid #eee;
  position: fixed;
  right: 0;
  top: 56px;
  z-index: 9999;
  overflow-y: auto;
  padding: 12px 12px 12px;
  box-shadow: 0px 8px 6px #ccc;
}
.message_list .list_card[data-v-ed8ee6e4] {
  width: 100%;
}
.message_list .list_card li[data-v-ed8ee6e4] {
  background: #fff;
  box-shadow: 0 2px 10px #ddd;
  border-radius: 8px;
  margin-bottom: 14px;
  border: 1px solid #eee;
}
.message_list .list_card li .top_tag[data-v-ed8ee6e4] {
  width: 100%;
  line-height: 30px;
  background: #f6f6f6;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
}
.message_list .list_card li .top_tag p[data-v-ed8ee6e4] {
  position: relative;
  padding-left: 15px;
  font-size: 13px;
}
.message_list .list_card li .top_tag .read[data-v-ed8ee6e4]::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #bbb;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.message_list .list_card li .top_tag .un_read[data-v-ed8ee6e4]::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: red;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.message_list .list_card li .message_main[data-v-ed8ee6e4] {
  padding: 13px 10px;
}
.message_list .list_card li .message_main h5[data-v-ed8ee6e4] {
  font-size: 12px;
  color: #333;
  font-weight: normal;
}
.message_list .list_card li .message_main h6[data-v-ed8ee6e4] {
  font-size: 12px;
  color: #333;
  font-weight: normal;
}
.message_list .list_card li .message_p[data-v-ed8ee6e4] {
  font-size: 12px;
  color: #666;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.message_list .list_card li .bottom_btns[data-v-ed8ee6e4] {
  width: 100%;
  margin: 0 auto;
  line-height: 30px;
  border-top: 1px solid #eee;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.message_list .list_card li .bottom_btns span[data-v-ed8ee6e4] {
  color: #666;
  font-size: 13px;
  line-height: 30px;
}
.message_list .list_card li .bottom_btns p[data-v-ed8ee6e4] {
  font-size: 13px;
  color: var(--themeColor, #17a2b8);
  line-height: 30px;
  cursor: pointer;
}
.message_list .list_card li .bottom_row[data-v-ed8ee6e4] {
  width: 100%;
  margin: 0 auto;
  border-top: 1px solid #eee;
}
.message_list .list_card li .bottom_row p[data-v-ed8ee6e4] {
  font-size: 13px;
  color: var(--themeColor, #17a2b8);
  cursor: pointer;
  text-align: right;
  display: inline-block;
  margin-right: 10px;
}
.message_list .list_card li .bottom_row .el-col[data-v-ed8ee6e4] {
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.message_list .list_card li .bottom_row .el-col span[data-v-ed8ee6e4] {
  display: inline-block;
  padding: 5px 0 5px 10px;
  font-size: 13px;
}
.message_detail h6[data-v-ed8ee6e4] {
  width: 96%;
  text-align: left;
  font-weight: normal;
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}
.message_detail p[data-v-ed8ee6e4] {
  width: 96%;
  margin: 0 auto;
  font-size: 15px;
  color: #333;
  line-height: 24px;
}
.message_detail .el-col[data-v-ed8ee6e4] {
  border-bottom: 1px solid #eee;
}
.message_detail .el-col span[data-v-ed8ee6e4] {
  display: inline-block;
  padding: 10px;
  font-size: 14px;
}
.icon_close[data-v-ed8ee6e4] {
  font-size: 18px;
  position: absolute;
  top: 19px;
  right: 20px;
  cursor: pointer;
  z-index: 999;
}
.all[data-v-ed8ee6e4] {
  font-size: 13px;
  position: absolute;
  top: 24px;
  right: 53px;
  cursor: pointer;
  z-index: 999;
  color: var(--themeColor, #17a2b8);
}
.each[data-v-ed8ee6e4] {
  display: flex;
}
.each .each_left[data-v-ed8ee6e4] {
  width: 100px;
  text-align: right;
}
.tax_sb_each[data-v-e38eadca] {
  width: 49%;
  padding: 3px 5px;
  border-radius: 4px;
  background: #eff9fa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 13px;
}
.tax_sb_each .iconfont[data-v-e38eadca] {
  margin-right: 5px;
  font-size: 13px;
}
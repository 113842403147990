.el-select[data-v-21f1da84] {
  width: 80%;
  margin-left: 0px;
}
.el-input[data-v-21f1da84] {
  width: 80%;
}
.xh[data-v-21f1da84] {
  color: var(--themeColor, #17a2b8);
  margin-left: 5px;
  cursor: pointer;
}
.content[data-v-03e50150] {
  display: flex;
  align-items: center;
  margin: 15px 0;
  position: relative;
  padding-left: 40px;
  cursor: pointer;
}
.content span[data-v-03e50150] {
  color: #17a2b8;
  margin-right: 15px;
}
.item_icon[data-v-03e50150] {
  display: inline-block;
  cursor: pointer;
}
.item_icon i[data-v-03e50150] {
  display: inline-block;
  font-size: 13px;
}
.item_icon p[data-v-03e50150] {
  display: inline-block;
  font-size: 13px;
  color: #333;
}
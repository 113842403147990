.aaaaa {
  margin-left: 5px;
}
.aaaaa .el-input__inner {
  background-color: #fff;
  font-size: 15px;
  font-weight: 400;
}
.dateStyle {
  display: flex;
  align-items: center;
  position: relative;
}
.dateStyle .el-input__inner {
  font-size: 15px;
  width: 110px;
  font-weight: 500;
  padding-left: 23px;
  padding-right: 0px;
}
.gongjuStyle {
  padding: 2px 0;
  cursor: pointer;
  position: relative;
  top: -4px;
}
.gongjuStyle img {
  width: 18px;
  height: 18px;
  position: relative;
  top: 4px;
  margin-right: 5px;
}
.header_fixed {
  width: 68px;
  height: 28px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 50px;
  right: 0;
  z-index: 99;
  background-color: rgba(27, 134, 221, 0.75);
  font-size: 13px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  cursor: pointer;
  color: #fff;
  padding-left: 15px;
}
@keyframes redImg {
0% {
    top: 0;
}
100% {
    top: 100%;
}
}
.redBox {
  position: absolute;
  /* opacity: 0;*/
  z-index: 10000;
  animation: redImg 3s infinite linear;
  -webkit-animation: redImg 3s infinite linear;
  -moz-animation: redImg 3s infinite linear;
  -ms-animation: redImg 3s infinite linear;
  -o-animation: redImg 3s infinite linear;
}
.redBox {
  position: fixed;
}
.redBox .roll {
  display: block;
  width: 40px;
}
.opera_style {
  color: black;
  margin: 0 auto;
  /* width: 100%; */
  text-align: center;
  z-index: 10000;
}
.opera_style a {
  color: black;
  font-size: 20px;
  letter-spacing: 1.5px;
  font-weight: bold;
}
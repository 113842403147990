@charset "UTF-8";
.big[data-v-038e7047] {
  height: 100%;
}
.big .top[data-v-038e7047] {
  width: 100%;
  height: 50px;
  background: #ccc;
}
.big .bottom[data-v-038e7047] {
  width: 100%;
  height: calc(100% - 45px);
  /*滚动条里面小方块*/
}
.big .bottom .left[data-v-038e7047] {
  float: left;
  width: var(--width);
  height: calc(100% - 24px);
}
.big .bottom .left[data-v-038e7047]::-webkit-scrollbar {
  width: 4px;
}
.big .bottom .left[data-v-038e7047]::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(100, 100, 100, 0.2);
}
.big .bottom .left1[data-v-038e7047] {
  float: left;
  width: var(--width);
  height: calc(100% - 24px);
}
.big .bottom .leftSecond[data-v-038e7047] {
  float: left;
  width: 25px;
  height: 94%;
}
.big .bottom .right[data-v-038e7047] {
  float: left;
  width: calc(100% - 1px);
  height: 100%;
  background: var(--themeColorBackground, #d9d9d9);
}
.big .bottom .right .rightStyle[data-v-038e7047] {
  float: right;
  width: calc(100% - var(--width));
}
.big .bottom .right .rightyStyle1[data-v-038e7047] {
  float: right;
  width: calc(100% - var(--width));
}
.logo[data-v-038e7047] {
  width: 130px;
  height: 50px;
  font-size: 14px;
  background-color: var(--themeColor, #17a2b8);
  background-image: var(--bigLogo);
  background-size: 100% 100%;
  position: relative;
  background-repeat: no-repeat;
}
.logo1[data-v-038e7047] {
  width: 50px;
  height: 50px;
  font-size: 14px;
  background-color: var(--themeColor, #17a2b8);
  background-image: var(--smallLogo);
  background-size: 80%;
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
}
.version[data-v-038e7047] {
  background-color: #2f4455;
  color: #fff;
  position: relative;
  left: 100px;
  top: 12px;
  padding: 1px 3px;
  display: inline-block;
  border-radius: 4px;
  font-size: 10px;
  font-weight: lighter;
}
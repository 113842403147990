@charset "UTF-8";
.tabZ[data-v-41d1770e] {
  display: flex;
  width: 100%;
}
.tabZ .leftTab[data-v-41d1770e] {
  width: 3%;
}
.tabZ .leftTab .tabList[data-v-41d1770e] {
  width: 40px;
  margin: 0 auto;
  line-height: 20px;
  writing-mode: vertical-lr; /*从左向右 从右向左是 writing-mode: vertical-rl;*/
  border: 0px;
  background-color: #efefef;
  padding: 16px 8px;
}
.tabZ .rightTab[data-v-41d1770e] {
  width: 95%;
}
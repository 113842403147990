@charset "UTF-8";
.chat_window[data-v-bf304814] {
  display: flex;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border-left: none;
  border-right: none;
}
.top_select[data-v-bf304814] {
  margin: 10px 0 10px 10px;
}
.com_list_style[data-v-bf304814] {
  border-radius: 4px;
  width: 320px;
  height: 58px;
  cursor: pointer;
  padding: 0 10px 0 15px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.com_name_one[data-v-bf304814] {
  width: 28px;
  height: 28px;
  border-radius: 10%;
  text-align: center;
  line-height: 28px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  margin-right: 5px;
}
.com_active[data-v-bf304814] {
  background-color: #f4f7ff;
}
.under_div[data-v-bf304814] {
  border: 1px solid #efefef;
  padding-bottom: 15px;
  height: 240px;
  position: relative;
  border-bottom: none;
}
.send_div[data-v-bf304814] {
  padding: 0 10px;
  margin-top: 5px;
  text-align: right;
}
.right_div[data-v-bf304814] {
  display: flex;
  flex-direction: column;
}
.right_div_buttondiv[data-v-bf304814] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-left: 1px solid #efefef;
  align-items: center;
}
.right_div_buttondiv .title_message[data-v-bf304814] {
  font-weight: bold;
  font-size: 14px;
}
.msg_content[data-v-bf304814] {
  background-color: #f4f7ff;
  padding-bottom: 10px;
  border-left: 1px solid #efefef;
}
.on_clounm[data-v-bf304814] {
  display: flex;
  justify-content: end;
  flex-direction: column;
}
.on_clounm img[data-v-bf304814] {
  width: 30px;
  height: 30px;
}
.time_div[data-v-bf304814] {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.time_div p[data-v-bf304814] {
  color: #a6a6a6;
  font-size: 12px;
}
.dives[data-v-bf304814] {
  max-width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
}
.val_p[data-v-bf304814] {
  font-size: 13px;
  max-width: 380px;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.dives_right[data-v-bf304814] {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.dives_left[data-v-bf304814] {
  background: #fff;
  margin-top: 2px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.input_div[data-v-bf304814] {
  padding: 10px 10px 0;
  position: relative;
}
.input_div .el-input[data-v-bf304814] {
  border: none;
  width: 100%;
}
.upload_div[data-v-bf304814] {
  position: absolute;
  bottom: 5px;
  left: 20px;
  z-index: 1;
}
[data-v-bf304814] .el-textarea__inner {
  height: 185px;
  overflow-y: auto;
  resize: none;
  box-shadow: 0 0 0 1px #e7ebeb;
}
[data-v-bf304814] .el-textarea__inner::-webkit-scrollbar {
  width: 0px;
}

/*滚动条里面小方块*/
[data-v-bf304814] .el-textarea__inner::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(100, 100, 100, 0.2);
}
.wd_style[data-v-bf304814] {
  font-size: 12px;
  text-align: center;
  background-color: #fd0002;
  color: #fff;
  border-radius: 50%;
  padding: 0 5px;
}
.read_all[data-v-bf304814] {
  padding-right: 20px;
  font-size: 13px;
  cursor: pointer;
  color: #17a2b8;
  display: flex;
  align-items: center;
}
.read_all .icon-qingchu[data-v-bf304814] {
  color: #17a2b8;
}
[data-v-bf304814] .el-upload-list {
  display: none;
}
.icon-wenjianleixing-biaozhuntu-gongzuobiao[data-v-bf304814] {
  color: #4cb050;
}
.icon-wenjianleixing-biaozhuntu-jishiben[data-v-bf304814] {
  color: #8289ad;
}
.icon-wenjianleixing-biaozhuntu-weizhiwenjian[data-v-bf304814] {
  color: #cccccc;
}
.icon-wenjianleixing-biaozhuntu-PDFwendang[data-v-bf304814] {
  color: #fa4e4e;
}
.icon-wenjianleixing-biaozhuntu-Wordwendang[data-v-bf304814] {
  color: #4876f9;
}
.iconfont[data-v-bf304814] {
  margin-right: 2px;
}
.tabs_box[data-v-bf304814] {
  display: flex;
  padding: 0 10px;
}
.tabs_box .tab_content[data-v-bf304814] {
  cursor: pointer;
  padding-bottom: 5px;
  margin-right: 30px;
  font-size: 14px;
}
.tabs_box .activeTab[data-v-bf304814] {
  border-bottom: 2px solid #17a2b8;
}
.daiban[data-v-bf304814] {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.daiban .el-icon[data-v-bf304814] {
  margin-right: 5px;
  font-size: 13px;
}
.daiban_text[data-v-bf304814] {
  margin-bottom: 2px;
}
.filter_box[data-v-bf304814] {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-bottom: 10px;
}
.tax_no[data-v-bf304814] {
  font-weight: normal;
  font-size: 11px;
}
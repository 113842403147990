.n-data-table-th {
  background: var(--themeColor, #17a2b8) !important;
}

.n-data-table {
  --n-merged-border-color: #b9b9b9 !important;
}

.n-data-table-th__title,
.n-data-table .n-data-table-th {
  color: #fff !important;
}

.n-data-table-thead .n-checkbox.n-checkbox--indeterminate .n-checkbox-box .n-checkbox-box__border {
  border-color: #fff;
}

.n-data-table-tbody .n-checkbox.n-checkbox--checked .n-checkbox-box .n-checkbox-box__border {
  border-color: var(--themeColor, #17a2b8);
}

.n-checkbox.n-checkbox--checked .n-checkbox-box,
.n-checkbox.n-checkbox--indeterminate .n-checkbox-box {
  background: var(--themeColor, #17a2b8) !important;
}

.n-data-table-td {
  padding: 0 10px;
}

.n-data-table .n-data-table-th {
  height: 40px;
  padding: 0 10px;
}

.n-data-table .n-data-table-th--selection {
  padding: 0px;
}

.n-data-table-thead .n-checkbox.n-checkbox--checked:focus:not(:active) .n-checkbox-box .n-checkbox-box__border,
.n-data-table-thead .n-checkbox:focus:not(:active) .n-checkbox-box .n-checkbox-box__border {
  border-color: #fff;
  box-shadow: unset;
}

.n-data-table-tbody .n-checkbox.n-checkbox--checked:focus:not(:active) .n-checkbox-box .n-checkbox-box__border,
.n-data-table-tbody .n-checkbox:focus:not(:active) .n-checkbox-box .n-checkbox-box__border {
  border-color: var(--themeColor, #17a2b8);
  box-shadow: unset;
}

.n-data-table:not(.n-data-table--single-line) .n-data-table-th {
  border-color: #b9b9b9;
}

.n-data-table-table,
.n-data-table-base-table-body {
  font-size: 13px !important;
}

.n-data-table .n-data-table-tr.n-data-table-tr--striped .n-data-table-td {
  background-color: var(--themeColorLeftMenu, #ecf7f9) !important;
}

.n-data-table:not(.n-data-table--single-line) .n-data-table-td {
  border-color: #b9b9b9;
}

.n-data-table .n-data-table-td {
  padding: 7px;
}

.n-data-table .n-data-table-th .n-data-table-resize-button {
  width: 1px;
  right: 3px;
}

.n-data-table .n-data-table-th .n-data-table-resize-button::after {
  width: 1px;
}

.n-steps .n-step-content .n-step-content-header {
  color: #333;
}

.n-steps .n-step-splitor {
  width: 530px;
}

.n-steps .n-step-indicator {
  --n-indicator-size: 20px;
}

.n-badge {
  top: -3px;
}

.n-badge .n-badge-sup {
  font-size: 11px;
  height: 14px;
  line-height: 14px;
  padding: 0 4px;
}

.headers[data-v-7cc40af9] {
  text-align: right;
  font-size: 20px;
  font-weight: bold;
  padding: 0px 20px;
}
.tableText[data-v-7cc40af9]{
  text-decoration: underline;
  cursor: pointer;
}
.tableText[data-v-7cc40af9]:hover{
  color: blue;
}
.foot[data-v-7cc40af9]{
  position: fixed;
  bottom: 20px;
  display: flex; /* 启用Flexbox布局 */
  justify-content: center; /* 水平居中子元素 */
  align-items: flex-end; /* 垂直对齐子元素到容器的底部 */
  margin-top: 20px
}
.drawerbg[data-v-7cc40af9]{
  width: 100%;
  height: 100%;
  background-image: url('https://file.listensoft.net/mnt/v2/img/b71b31ba2f7a4987ceddfd51a125dbd.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.drawerText[data-v-7cc40af9]{
  text-align: center;
  position: relative;
  top: 48%;
}
.drawerText div[data-v-7cc40af9]{
  margin: 8px 0;
}
.drawerText > div[data-v-7cc40af9]:first-child { /* 假设第一个<div>是你想要居中的元素 */  
  display: flex;  
  justify-content: center; /* 水平居中 */  
  align-items: center; /* 垂直居中，如果需要的话 */
}
[data-v-7cc40af9] .el-drawer__body{
  padding: 0!important;
  overflow: hidden;
}

td[data-v-5fd7866d] {
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  line-height: 25px;
  padding: 0 !important;
  color: #333;
  font-size: 14px;
}
input[data-v-5fd7866d] {
  width: calc(100% - 22px);
  line-height: 24px;
  background: rgb(241, 249, 253);
  font-size: 14px;
  padding-right: 20px;
  text-align: right;
  outline: none;
  color: #333;
  border: none;
}
p[data-v-5fd7866d] {
  line-height: 27px;
  height: 27px;
  font-size: 14px;
  padding-right: 20px;
  text-align: right;
  color: #333;
}
.disabled[data-v-5fd7866d] {
  background: rgb(239, 239, 239);
}
.subject_option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 10000;
}
.subject_option_red {
  color: red;
  font-size: 12px;
}
.subject_option_theme {
  color: var(--themeColor, #17a2b8);
  font-size: 12px;
}
.n-base-select-option {
  display: grid !important;
}
.n-base-select-menu .n-base-select-option.n-base-select-option--pending::before {
  background-color: #e5e5eb;
}
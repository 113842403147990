@charset "UTF-8";
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  font-size: 100%;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}

ul,
li {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}

input {
  border: none;
  background: none;
}

view {
  box-sizing: border-box;
}

.el-button {
  font-size: 13px !important;
}

.el-tabs__item {
  font-size: 13px;
}

.el-tabs__item.is-active {
  color: var(--themeColor, #17a2b8) !important;
}

.el-tabs__item:hover {
  color: var(--themeColor, #17a2b8) !important;
}

.el-tabs__active-bar {
  background-color: var(--themeColor, #17a2b8);
}

.el-select .el-input__inner {
  border-radius: 0 !important;
}

.el-input__wrapper {
  border-radius: 0 !important;
}

.el-table {
  --el-table-border-color: #b9b9b9;
}

.el-table th.el-table__cell > .cell {
  color: #fff;
}

.el-button--mini {
  padding: 2px 10px !important;
}

.tag {
  border-radius: 0 !important;
}

.el-input {
  border-radius: 0 !important;
}

.el-input__inner {
  border-radius: 0 !important;
}

.el-textarea__inner {
  border-radius: 0 !important;
}

.el-button--primary {
  background: var(--themeColor, #17a2b8);
  color: #fff;
  border-color: var(--themeColor, #17a2b8);
}

.el-button--primary:hover {
  background-color: var(--themeColor, #17a2b8);
  border-color: var(--themeColor, #17a2b8);
}

.el-button--primary:active {
  background: var(--themeColor, #17a2b8) !important;
  color: #fff !important;
  border-color: var(--themeColor, #17a2b8) !important;
}

.el-button--primary:focus {
  background: var(--themeColor, #17a2b8) !important;
  color: #fff !important;
  border-color: var(--themeColor, #17a2b8) !important;
}

.el-button--primary.is-plain {
  color: var(--themeColor, #17a2b8);
  background: var(--themeColorPlain);
  border-color: var(--themeColorPlainBc);
}

.el-button--primary.is-plain:hover {
  color: white;
  background: var(--themeColor, #17a2b8);
  border-color: var(--themeColorPlainBc);
}

.el-button--primary.is-plain:active {
  color: var(--themeColor, #17a2b8);
  background: var(--themeColorPlain);
  border-color: var(--themeColorPlainBc);
}

.el-button--primary.is-plain:focus {
  color: var(--themeColor, #17a2b8);
  background: var(--themeColorPlain);
  border-color: var(--themeColorPlainBc);
}

.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
  color: #bcbec2;
  background-color: #f4f4f5;
  border-color: #e9e9eb;
}

.el-button--primary.is-plain.is-disabled,
.el-button--primary.is-plain.is-disabled:active,
.el-button--primary.is-plain.is-disabled:focus,
.el-button--primary.is-plain.is-disabled:hover {
  color: #bcbec2;
  background-color: #f4f4f5;
  border-color: #e9e9eb;
}

.el-button--warning {
  background: #c69c6d;
  color: #fff;
  border-color: #c69c6d;
}

.el-button--warning:hover {
  background-color: rgba(198, 156, 109, 0.8);
  border-color: rgba(198, 156, 109, 0.7);
  color: #fff;
}

.el-button--warning:active {
  background: #c69c6d !important;
  color: #fff !important;
  border-color: #c69c6d !important;
}

.el-button--warning:focus {
  background: #c69c6d !important;
  color: #fff !important;
  border-color: #c69c6d !important;
}

.el-button--warning.is-plain {
  color: #c69c6d;
  background: #f2e9de;
  border-color: #e2ccb4;
}

.el-button--warning.is-plain:hover {
  color: white;
  background: #c69c6d;
  border-color: #e2ccb4;
}

.el-button--warning.is-plain:active {
  color: #c69c6d;
  background: #f2e9de;
  border-color: #e2ccb4;
}

.el-button--warning.is-plain:focus {
  color: #c69c6d;
  background: #f2e9de;
  border-color: #e2ccb4;
}

.el-button--danger {
  background: #fe696a;
  color: #fff;
  border-color: #fe696a;
}

.el-button--danger:hover {
  background-color: rgba(254, 105, 106, 0.8);
  border-color: rgba(251, 196, 196, 0.7);
  color: #fff;
}

.el-button--danger:active {
  background: #fe696a !important;
  color: #fff !important;
  border-color: #fe696a !important;
}

.el-button--danger:focus {
  background: #fe696a !important;
  color: #fff !important;
  border-color: #fe696a !important;
}

.el-button--danger.is-plain {
  color: #fe696a;
  background: #fef0f0;
  border-color: #fbc4c4;
}

.el-button--danger.is-plain:hover {
  color: white;
  background: #fe696a;
  border-color: #fbc4c4;
}

.el-button--danger.is-plain:active {
  color: #fe696a;
  background: #fef0f0;
  border-color: #fbc4c4;
}

.el-button--danger.is-plain:focus {
  color: #fe696a;
  background: #fef0f0;
  border-color: #fbc4c4;
}

.el-button.is-link {
  color: var(--themeColor, #17a2b8);
}

.el-button.is-link:hover {
  color: var(--themeColor, #17a2b8);
}

.el-button--danger.is-link {
  color: #f56c6c !important;
}

.el-button.is-link:not(.is-disabled):hover,
.el-button.is-link:not(.is-disabled):active {
  color: #a8abb2 !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.el-button.is-link:not(.is-disabled):focus {
  background-color: transparent !important;
  border-color: transparent !important;
}

.el-button.is-link.is-disabled {
  color: #a8abb2 !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.el-button--success {
  border-color: #b3e19d !important;
}

.el-tag--plain {
  color: var(--themeColor, #17a2b8);
  border-color: var(--themeColor, #17a2b8);
}

.el-table tr th {
  background: var(--themeColor, #17a2b8) !important;
}

.el-table .sort-caret.ascending {
  border-bottom-color: #fff !important;
}

.el-table .sort-caret.descending {
  border-top-color: #fff !important;
}

.el-dropdown__popper {
  border: none !important;
}

.el-popper__arrow {
  display: none !important;
}

.el-menu {
  width: 100%;
}

.el-menu-item.is-active {
  color: var(--themeColor, #17a2b8) !important;
}

.el-button--text {
  color: var(--themeColor, #17a2b8);
}

.el-pager li.active {
  color: var(--themeColor, #17a2b8);
}

.el-switch.is-checked .el-switch__core {
  background: var(--themeColor, #17a2b8) !important;
  color: var(--themeColor, #17a2b8) !important;
  border-color: var(--themeColor, #17a2b8) !important;
}

.el-switch__label.is-active {
  color: var(--themeColor, #17a2b8) !important;
}

.el-radio__input.is-checked + .el-radio__label {
  color: var(--themeColor, #17a2b8) !important;
}

.el-radio__input.is-checked .el-radio__inner {
  border-color: var(--themeColor, #17a2b8) !important;
  background: var(--themeColor, #17a2b8) !important;
}

.el-radio.is-bordered.is-checked {
  border-color: var(--themeColor, #17a2b8) !important;
}

.el-radio-button__original-radio:checked + .el-radio-button__inner {
  border-color: var(--themeColor, #17a2b8) !important;
  background: var(--themeColor, #17a2b8) !important;
}

.el-checkbox__input.is-checked .el-checkbox__inner {
  color: var(--themeColor, #17a2b8) !important;
  background: var(--themeColor, #17a2b8) !important;
  border-color: #fff;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: var(--themeColor, #17a2b8) !important;
}

.el-select-dropdown__item.selected {
  color: var(--themeColor, #17a2b8) !important;
}

.el-input__inner {
  border-color: #c2c2c2 !important;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.clearfix:before {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.clearfix {
  zoom: 1;
}

.btn_left {
  float: left;
}

.btn_right {
  float: right;
}

view,
div {
  box-sizing: border-box;
}

.el-form-item {
  margin-bottom: 15px;
}

.el-form-item__label {
  line-height: 30px !important;
  font-size: 13px;
}

.el-form-item--small .el-form-item__label {
  height: 30px;
}

.el-form-item__content {
  line-height: 30px !important;
}

.el-radio__label {
  font-size: 13px;
}

.home {
  padding: 10px;
}

.header {
  padding: 10px 10px 10px 0;
}

.el-select-dropdown__item {
  font-size: 13px;
  height: 28px;
  line-height: 28px;
}

.el-pagination .el-select .el-input .el-input__inner {
  font-size: 14px;
}

.el-sub-menu .el-menu-item {
  line-height: 42px !important;
  height: 50px !important;
  font-size: 14px;
  color: #666;
}

.el-menu--horizontal {
  border-bottom: none;
}

.app-container {
  padding: 10px 20px;
  width: 100%;
  margin: 0 auto 0px auto;
  max-height: calc(100vh - 110px);
  position: relative;
}

.el-select {
  margin: 0 5px;
}

.pagination {
  margin-top: 16px;
  float: right;
}

.bottom_button {
  float: left;
  margin-top: 16px;
}

.bottom_style {
  margin-top: 26px;
}

.common_dialog_box {
  width: 100%;
  margin: 0 auto;
}

.common_dialog_box .common_title {
  font-size: 14px;
  color: var(--themeColor, #17a2b8);
  font-weight: normal;
  line-height: 36px;
  border-bottom: 1px solid #c2c2c2;
  margin-bottom: 10px;
}

.common_dialog_box .common_title span {
  color: var(--themeColor, #17a2b8);
  margin-left: 30px;
  font-weight: normal !important;
}

.common_dialog_box .item:nth-child(2n+1) {
  margin-left: 0;
}

.new_dialog .el-button--primary {
  background: var(--themeColor, #17a2b8);
  border-color: var(--themeColor, #17a2b8);
}

.el-form-item__label {
  font-size: 13px;
  font-weight: 500;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.clearfix:before {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.clearfix {
  zoom: 1;
}

.filter-container {
  padding-bottom: 10px;
  margin-top: 5px;
}
.filter-container .filter-item {
  height: 24px;
}
.filter-container .filter-item-s {
  margin-bottom: 10px;
}

.border_none .el-input__inner {
  border: none !important;
}

.border_none .el-cascader .el-input .el-input__inner {
  border: none !important;
}

.border_none .el-select-v2__wrapper {
  border: none !important;
}

.el-form-item--medium .el-form-item__content,
.el-form-item--medium .el-form-item__label {
  line-height: 24px;
}

.fixed_box_table {
  overflow-y: overlay;
  overflow-x: hidden;
}

.fixed_box_table .content {
  border-top: 0 !important;
  margin-top: 0 !important;
  border-bottom: 1px solid #c2c2c2;
}

.top_bg {
  font-size: 13px;
  background-color: var(--themeColor, #17a2b8);
  color: #fff !important;
}

.content .top_bg td {
  font-weight: 500 !important;
  color: #fff !important;
}

.tab_content .el-tabs__content {
  min-height: 800px;
}

.xgmStyle {
  border: 1px solid var(--themeColor, #17a2b8);
  background: var(--themeColorPlain);
  color: var(--themeColor, #17a2b8);
  padding: 0 1px;
  margin-right: 5px;
}

.ybStyle {
  border: 1px solid #ff7a00;
  background: #fef0f0;
  color: #ff7a00;
  padding: 0 1px;
  margin-right: 5px;
}

.gtStyle {
  border: 1px solid #14831d;
  background: #f0fef2;
  color: #14831d;
  padding: 0 1px;
  height: 15px;
  line-height: 15px;
  margin-left: 3px;
}

.el-table tr .el-table__row .el-table__row--striped {
  background-color: var(--themeColorLeftMenu, #ecf7f9);
}

.el-table__row--striped {
  background-color: var(--themeColorLeftMenu, #ecf7f9) !important;
}

.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background-color: var(--themeColorLeftMenu, #ecf7f9);
}

.el-table th {
  font-size: 13px;
  font-weight: 500;
}

.el-table tr {
  font-size: 13px;
  font-weight: 500;
}

.el-dropdown-menu--mini .el-dropdown-menu__item {
  line-height: 30px !important;
}

.el-dropdown-menu--small .el-dropdown-menu__item {
  font-size: 13px !important;
}

/* 超出一行显示... */
.el-table .cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 8px;
}

.el-table .el-table__cell {
  padding: 5px 0;
}

.el-table--border .el-table__cell {
  border-right: 1px solid #b9b9b9;
  border-bottom: 1px solid #b9b9b9 !important;
}

.el-checkbox-button__inner {
  border: 1px solid #dcdfe6;
}

.el-checkbox-button:first-child .el-checkbox-button__inner {
  border-radius: inherit;
}

.el-transfer-panel__item.el-checkbox .el-checkbox__label {
  overflow: unset;
}

.heightDetail {
  overflow: auto;
}

.sbcg2 {
  padding: 0 !important;
  color: #67c23a;
}

.wsb {
  padding: 0 !important;
  color: #333;
}

.sbz {
  padding: 0 !important;
  color: #409eff;
}

.sbyc2 {
  color: red !important;
  padding: 0 !important;
}

.yczt {
  color: #e6a23c !important;
  padding: 0 !important;
}

.icon-gantanhao {
  color: #afacac;
}

.gantanhao-yc {
  color: #e6a23c;
}

.icon-info {
  color: #409eff;
}

.icon-cuowu {
  color: red;
}

.icon-duihao {
  color: #67c23a;
}

.gantanhao-blue {
  color: #409eff;
}

.duihao-yc {
  color: #e6a23c;
}

.duihao-blue {
  color: #409eff;
}

.gantanhao-cw {
  color: red;
}

.el-scrollbar__bar.is-horizontal {
  height: 10px;
}

.el-scrollbar__bar.is-vertical {
  width: 10px;
}

.el-progress-bar .el-progress-bar__innerText {
  position: relative !important;
  top: -4px;
}

.tianshu {
  background: #ecf5ff;
}

.tianshu2 {
  background: none;
}

.onlyTooltip .el-popper {
  max-width: 40%;
  padding-bottom: 5px !important;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 15;
  -webkit-box-orient: vertical;
}

.onlyTooltip .el-popper,
.onlyTooltip .el-popper.is-dark {
  max-width: 40% !important;
  background: rgba(0, 0, 0, 0.95) !important;
  color: #fff !important;
  line-height: 24px;
}

.el-pager li.is-active,
.el-pager li:hover {
  color: var(--themeColor);
}

.el-pagination {
  font-size: 13px;
}

.el-tabs--border-card > .el-tabs__header .el-tabs__item {
  color: #333;
}

.el-table {
  color: #333;
}

.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border-color: #fff;
  background: var(--themeColor, #17a2b8) !important;
  color: var(--themeColor, #17a2b8) !important;
}

.el-checkbox-button.is-checked .el-checkbox-button__inner {
  border-color: var(--themeColor, #17a2b8) !important;
  background: var(--themeColor, #17a2b8) !important;
}

.el-checkbox-button {
  margin-right: 5px;
  margin-bottom: 3px;
}

textarea:focus-visible {
  outline: none;
}

textarea {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #333;
  height: 50px;
  padding-left: 10px;
}

.el-select-v2__wrapper {
  border-radius: 0 !important;
  border: 1px solid #b9b9b9;
}

.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: #fff;
}

.el-badge__content {
  padding: 0px 5px;
  background-color: #b13633;
  height: 18px;
  border: none;
  font-size: 11px;
}

.el-dialog {
  margin-top: 1vh;
}

.input_checkbox input[type=checkbox] {
  -webkit-appearance: none;
  width: 16px !important;
  height: 16px !important;
  line-height: 16px !important;
  border: 1px solid #949494;
  position: relative;
}

.input_checkbox input[type=checkbox]:checked {
  border: none;
  background-color: var(--themeColor, #17a2b8) !important;
  color: var(--themeColor, #17a2b8) !important;
}

.input_checkbox input[type=checkbox]:checked::after {
  content: "✓";
  position: absolute;
  top: 0;
  width: 16px;
  height: 16px;
  color: #fff;
  text-align: center;
}

.el-button.is-text {
  color: var(--themeColor, #17a2b8);
}

.top_title3 {
  width: 100%;
  margin: 0 auto;
}
.top_title3 h4 {
  font-size: 15px;
  color: #333;
  font-weight: 600;
  text-align: center;
}
.top_title3 h5 {
  font-size: 13px;
  color: #333;
  font-weight: normal;
  text-align: center;
  margin-top: 3px;
}
.top_title3 .bottom_font {
  width: 100%;
  margin: 0 auto;
  margin-top: 0;
}
.top_title3 .bottom_font h6 {
  float: left;
  width: 33.33%;
  font-size: 13px;
  color: #333;
  font-weight: normal;
}
.top_title3 .bottom_font h6:nth-child(1) {
  padding-top: 10px;
}
.top_title3 .bottom_font h6:nth-child(2) {
  padding-top: 10px;
}

.save_box .icon-baocun {
  font-size: 13px;
  margin-right: 3px;
}

.date-icon .el-icon:before {
  font-family: "iconfont" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  content: "\e66b";
}

.el-table__column-filter-trigger {
  position: absolute;
  right: 15px;
}

.el-table__column-filter-trigger i {
  font-size: 13px;
}

.icon-fuzhi1 {
  font-size: 15px !important;
}

.el-input__validateIcon {
  color: #67c23a;
}

.el-progress-bar__outer {
  background-color: #d5d9e3;
}

.el-dialog__header {
  border-bottom: 1px solid #eee;
  padding: 10px 0 10px 20px;
  margin-right: 0;
}

.el-dialog__headerbtn {
  top: 0px;
}

.el-input {
  --el-input-border-color: #b9b9b9 !important;
}

.el-button {
  --el-button-border-color: #b9b9b9 !important;
}

.el-date-editor {
  --el-input-border-color: #b9b9b9 !important;
}

.el-textarea {
  --el-input-border-color: #b9b9b9 !important;
}

.el-radio-group {
  border-color: #b9b9b9 !important;
}

.el-dialog__title {
  font-size: 14px !important;
}

.bg_color {
  background-color: #efefef;
}

.el-table-filter__bottom button.is-disabled {
  background: var(--themeColor, #17a2b8);
  color: #fff;
  border-color: var(--themeColor, #17a2b8);
}

.el-table-filter__bottom button {
  background: 1 2;
  border: 1px solid #b9b9b9;
  float: right;
  margin-bottom: 10px;
  margin-left: 10px;
  border-radius: 3px;
  padding: 2px 11px;
}

.el-table-filter__bottom button:nth-child(1) {
  background: var(--themeColor, #17a2b8);
  color: #fff;
  border-color: var(--themeColor, #17a2b8);
}

.el-table-filter__checkbox-group {
  padding: 20px;
}

.el-transfer-panel .el-transfer-panel__header .el-checkbox .el-checkbox__label {
  font-size: 13px;
}

.el-transfer-panel .el-transfer-panel__header {
  background-color: var(--themeColorLeftMenu, #ecf7f9);
}

.el-dialog__header {
  background-image: url(../img/dialogBg.6cea5d02.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.el-drawer__header {
  margin-bottom: 0px !important;
  color: black;
  background-image: url(../img/dialogBg.6cea5d02.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 12px 20px !important;
}

.el-drawer__title {
  font-size: 13px;
}

.icon-zhaoshangyinhang {
  color: #af191b;
}

.icon-nongyeyinhang {
  color: #009883;
}

.icon-yinhang-zhongguoyinhang {
  color: #a40428;
}

.icon-jiaotongyinhang {
  color: #091f5c;
}

.icon-jiansheyinhang {
  color: #0061ae;
}

.icon-guangdayinhang {
  color: #631a7e;
}

.icon-youzhengyinhang {
  color: #026649;
}

.icon-ningboyinhang {
  color: #f3d212;
}

.icon-anhuinongjin-icon {
  color: #da4d16;
}

.icon-jiangsuyinhang {
  color: #095caa;
}

.icon-huaxiayinhang {
  color: #d80c18;
}

.el-checkbox__label {
  font-size: 13px;
}

.icon-changshunongshangyinhang {
  color: #d7d600;
}

.icon-jiangsunongcunshangyeyinhang {
  color: #e38622;
}

.icon-laishangyinhang {
  color: #e3222f;
}

.icon-qiluyinhang {
  color: #009fdc;
}

.icon-shenzhennongshangyinhang {
  color: #fcc800;
  font-size: 15px !important;
}

.icon-zhongguominshengyinhang {
  color: #00a65d;
}

.icon-yinhanglogo- {
  color: #026841;
}

.icon-jiningyinhang {
  color: #ffac0e;
}

.icon-ziyuannongshang {
  color: #2b823b;
}

.icon-chengdeyinhang {
  color: #b82e2b;
}

.icon-linshangyinhang {
  color: #ec1d24;
}

.icon-hubeiyinhanglogo {
  color: #e7160f;
}

.icon-langfangyinhang {
  color: #dc0a2b;
}

.icon-yinhanglogo-1 {
  color: #ca1d1d;
}

.icon-hengshuiyinhang {
  color: #194594;
}

.icon-hebeiyinhang {
  color: #41b051;
}

.icon-zhongxinyinhang {
  color: #d70010;
}

.icon-guilinyinhang {
  color: #771f77;
}

.icon-hangzhouyinxing {
  color: #0599df;
}

.icon-weihaishangye {
  color: #005ead;
}

.icon-xinjiangnongcunxinyongshe {
  color: #4dcbf1;
}

.icon-shanghainongshangyinhang {
  color: #00469b;
}

.icon-taian {
  color: #d9232e;
}

.icon-huiji {
  color: var(--themeColor, #17a2b8);
}

.el-select-dropdown__option-item.is-selected:not(.is-multiple) {
  color: var(--themeColor, #17a2b8) !important;
}

.swbb_table_width {
  width: 1200px;
  max-width: 100%;
}

.auto_content {
  background-color: #d8ecff;
  margin: 5px;
  font-size: 12px;
  position: relative;
}
.auto_content .iconfont {
  font-size: 18px;
  color: #17a2b8;
}
.auto_content .date_select {
  position: absolute;
  top: 22px;
  right: 7px;
}
.auto_content .auto_content_size {
  display: flex;
  align-items: center;
  width: 98%;
  margin: 0 auto;
  height: 70px;
  color: #333;
}
.auto_content .auto_content_size .each {
  width: 140px;
  display: flex;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
}
.auto_content .auto_content_size .each span {
  font-size: 13px;
  font-weight: 600;
  color: #000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.auto_content .auto_content_size .each_text {
  margin-left: 5px;
}
.auto_content .auto_content_size .each_text div {
  margin-bottom: 5px;
}
.auto_content .auto_content_size .each_text a {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-color: #141720;
          text-decoration-color: #141720;
}
.auto_content .auto_content_size .icon_bg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #aedcfb;
  text-align: center;
  line-height: 30px;
}

.ml_12 {
  margin-left: 12px;
}

.totalStyle {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 13px;
  color: #333;
}

.home_echarts {
  width: 34% !important;
  height: 100%;
  display: inline-block;
  position: relative;
  top: 0px;
  left: 5px;
}

.home_content {
  display: inline-block;
  height: 100%;
  width: 66%;
  position: relative;
  bottom: 20px;
  left: -12px;
}

.home_content_text {
  position: relative;
  top: 61px;
  left: 15px;
  line-height: 22px;
}
.home_content_text ul li {
  color: #333;
  font-size: 10px;
}

.home_progress {
  width: 100%;
  padding-left: 48px;
  position: relative;
  top: -2px;
}
.home_progress span {
  padding-left: 3px;
  font-size: 10px;
  color: #333;
}
.home_progress .home_progress_each {
  width: 100%;
  display: flex;
  margin-bottom: 5px;
}
.home_progress .home_progress_each:last-child {
  margin-bottom: 0;
}
.home_progress .el-progress-bar__inner {
  text-align: left !important;
}

.el-slider__bar {
  background-color: var(--themeColor, #17a2b8);
}

.el-slider__button {
  border-color: var(--themeColor, #17a2b8);
}

.auto_home .search_btn {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-right: 5px;
}

.auto_home .el-radio-button__original-radio:checked + .el-radio-button__inner {
  border-color: #67c23a !important;
  background: #67c23a !important;
}

.chat_drawer .el-drawer__body {
  padding: 0 !important;
}

.padding-dialog .el-dialog__body {
  padding: 10px 20px !important;
}

.el-popover.el-popper {
  min-width: 110px !important;
}

.el-popper {
  max-width: 500px !important;
}
.load_box[data-v-77dfa688] {
  text-align: left !important;
}
.load_box a[data-v-77dfa688] {
  transform: translateY(-1px);
  display: inline-block;
  padding: 0 20px;
  text-align: center;
  line-height: 28px;
  font-size: 14px;
  color: #fff;
  background: #67c23a;
}
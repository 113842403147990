.tit[data-v-f5a6d4ca] {
  margin: 3px 10px;
  vertical-align: middle;
}
.tit img[data-v-f5a6d4ca] {
  vertical-align: middle;
  margin-top: -2px;
}
.tit span[data-v-f5a6d4ca] {
  margin-left: 3px;
}
.none_border[data-v-f5a6d4ca] .el-input {
  --el-input-border-color: #fff !important;
}
.none_border[data-v-f5a6d4ca] .el-input__wrapper:hover {
  box-shadow: none;
}
[data-v-f5a6d4ca] .el-popover.el-popper {
  min-width: 0;
}
.iconfont1[data-v-f5a6d4ca] {
  color: white;
}
.header-big[data-v-f5a6d4ca] {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--themeColorHeaderBg, #304456);
  font-size: 14px;
}
.left_header[data-v-f5a6d4ca] {
  width: 59%;
  height: 50px;
}
.right_header[data-v-f5a6d4ca] {
  height: 50px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.header-message[data-v-f5a6d4ca] {
  display: flex;
  align-items: center;
  margin-left: 8px;
  cursor: pointer;
  color: #fff;
}
.el-icon[data-v-f5a6d4ca] {
  margin-right: 5px;
}
.el-scrollbar .el-dropdown-menu[data-v-f5a6d4ca] {
  padding: 5px 0;
  background: #fff;
  border-radius: 0px;
}
[data-v-f5a6d4ca] .el-dropdown-menu__item:not(.is-disabled):focus {
  color: var(--themeColor, #17a2b8) !important;
  background-color: #f0f9f8 !important;
}
.nav1[data-v-f5a6d4ca] {
  display: inline-block;
  padding: 0px;
  margin-left: 3px;
}
.nav1 span[data-v-f5a6d4ca] {
  color: #333;
  font-size: 16px;
  width: 135px;
  font-weight: 500;
  line-height: 52px;
  cursor: pointer;
}
.cur[data-v-f5a6d4ca] {
  border-bottom: 3px solid var(--themeColor, #17a2b8);
}
.has_com[data-v-f5a6d4ca] {
  width: 100%;
  float: left;
  height: 50px;
  display: flex;
  align-items: center;
  color: #fff;
  margin-left: 25px;
}
ul li[data-v-f5a6d4ca] {
  font-size: 13px;
}
.header-ai[data-v-f5a6d4ca] {
  height: 30px;
  background-color: #000;
  padding: 0 10px;
  border-radius: 30px;
}
.money[data-v-f5a6d4ca] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.money .money_img[data-v-f5a6d4ca] {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -12%);
  top: 25%;
  z-index: 999;
}
.money .ai_close[data-v-f5a6d4ca] {
  text-align: center;
}
.money .money_text[data-v-f5a6d4ca] {
  position: absolute;
  left: 126px;
  font-size: 35px;
  font-weight: bold;
  top: 178px;
}
.money .money_btn[data-v-f5a6d4ca] {
  position: absolute;
  left: 88px;
  font-size: 35px;
  font-weight: bold;
  bottom: 43px;
}
.top_select[data-v-143dbed4] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.item_icon[data-v-143dbed4] {
  display: flex;
  align-items: center;
}
.item_icon i[data-v-143dbed4] {
  display: inline-block;
  font-size: 13px;
}
.item_icon .iconfont icon-cuowu[data-v-143dbed4] {
  color: #f56c6c;
}
.item_icon .iconfont icon-duihao[data-v-143dbed4] {
  color: #67c23a;
}
.item_icon .iconfont icon-cuowu[data-v-143dbed4] {
  color: #e6a23c;
}
.item_icon .iconfont icon-info[data-v-143dbed4] {
  color: #409eff;
}
.item_icon p[data-v-143dbed4] {
  display: inline-block;
  font-size: 14px;
  line-height: 28px;
  color: #333;
  margin-left: 3px;
}
#app {
  color: #2c3e50;
  --bigLogo:url("https://file.listensoft.net/mnt/v2/html/pics/jsplogo.svg");
  --smallLogo:url("https://file.listensoft.net/mnt/v2/html/pics/jsplogo1.svg");
  --menuBg:url("https://file.listensoft.net/mnt/v2/html/pics/menuBg.svg") ;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
body {
  font-family: Mysh !important;
}
:root {
  --themeColor: #17a2b8;
  --themeColorLeftMenu: #ecf7f9;
  --themeColorPlain: #f0f9f8;
  --themeColorPlainBc: #c3e5e3;
  --themeColorHeaderBg:#304456;
  --themeColorMenuBg:#384e63;
  --themeColorMenuBottom:#17a2b8;
  --themeColorBackground:#d9d9d9;
  --themeColorStepBack:#17a2b833;
}
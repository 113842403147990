.el-table .cell .el-radio-group[data-v-598f1fe2] {
  white-space: pre-wrap !important;
  text-align: left;
}
.checkbox-wrap .el-checkbox[data-v-598f1fe2] {
  height: 30px;
}
[data-v-598f1fe2] .el-checkbox {
  width: 130px;
  margin-right: 0;
  height: 26px;
  --el-checkbox-checked-bg-color: var(--themeColor, #17a2b8);
}
.el-checkbox-group[data-v-598f1fe2] .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #333 !important;
}
.el-popover[data-v-598f1fe2] .el-popper.is-light {
  background: #ecf0f5;
}
.vxe-table--render-default.border--full .vxe-table--header-wrapper {
  background: var(--themeColor, #17a2b8);
  color: #fff;
}

.vxe-table--render-default.size--mini {
  font-size: 13px;
}

.vxe-table--render-default .vxe-body--row.row--stripe {
  background: var(--themeColorLeftMenu, #ecf7f9);
}

.vxe-cell--title .vxe-cell--checkbox .vxe-checkbox--icon {
  background: #fff;
  border-radius: 2px;
}

.vxe-table--render-default .vxe-cell--checkbox:not(.is--disabled):hover .vxe-checkbox--icon {
  color: var(--themeColor, #17a2b8);
}

.vxe-table--render-default .vxe-cell--title .vxe-cell--checkbox .vxe-checkbox--icon:before,
.vxe-table--render-default .vxe-cell--title .vxe-cell--checkbox:not(.is--disabled):hover .vxe-checkbox--icon:before {
  border-color: #fff !important;
}

.vxe-table--render-default .is--checked.vxe-cell--checkbox .vxe-checkbox--icon:before {
  border-color: var(--themeColor, #17a2b8);
  background-color: var(--themeColor, #17a2b8);
}

.vxe-table--render-default.border--full .vxe-header--column,
.vxe-table--render-default.border--full .vxe-body--column {
  background-image: linear-gradient(#b9b9b9, #b9b9b9), linear-gradient(#b9b9b9, #b9b9b9);
}

.vxe-table--render-default.size--mini .vxe-footer--column:not(.col--ellipsis),
.vxe-table--render-default.border--full .vxe-footer--column {
  background-image: linear-gradient(#b9b9b9, #b9b9b9);
}

.vxe-table--render-default .vxe-table--footer-wrapper {
  border-color: #b9b9b9;
}

.vxe-table--render-default {
  color: #333;
}

.vxe-table .vxe-sort--asc-btn, .vxe-table .vxe-sort--desc-btn {
  color: #fff;
}

.vxe-table .vxe-sort--asc-btn.sort--active, .vxe-table .vxe-sort--desc-btn.sort--active {
  color: #fff;
}

.vxe-table--render-default .is--indeterminate.vxe-cell--checkbox .vxe-checkbox--icon:before {
  border-color: var(--themeColor, #17a2b8);
  background-color: var(--themeColor, #17a2b8);
}

.vxe-header--column {
  font-weight: 500;
}
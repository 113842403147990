@media screen and (min-width: 1280px) and (max-width: 1366px) {
.big_box3[data-v-7a17b3ec] {
    width: 1000px !important;
}
}
.big_box3[data-v-7a17b3ec] {
  width: 100%;
  padding: 20px;
  background: #fff;
  box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  border: 1px solid #ddd;
}
.big_box3 .bottom_time[data-v-7a17b3ec] {
  float: left;
  width: 40%;
}
.big_box3 .top_input2[data-v-7a17b3ec] {
  width: 50%;
}
.big_box3 .top_input2 h6[data-v-7a17b3ec] {
  display: inline-block;
  width: 40px;
  font-size: 14px;
  font-weight: normal;
}
.big_box3 .top_input3[data-v-7a17b3ec] {
  width: 50%;
}
.big_box3 .top_input3 h6[data-v-7a17b3ec] {
  float: left;
  font-size: 15px;
  color: #333;
  font-weight: normal;
  line-height: 36px;
  margin-right: 10px;
}
.big_box3 .top_input3 span[data-v-7a17b3ec] {
  font-size: 15px;
  color: #333;
  font-weight: normal;
  line-height: 36px;
  margin-left: 10px;
}
.big_box3 .top_input3 input[data-v-7a17b3ec] {
  float: left;
  width: 47%;
  border: none;
  border-bottom: 1px solid #666;
  margin-left: 5px;
  line-height: 22px;
}
.top_title[data-v-7a17b3ec] {
  width: 100%;
  margin: 0 auto;
}
.top_title h5[data-v-7a17b3ec] {
  font-size: 30px;
  color: #333;
  text-align: center;
  font-weight: 500;
}
.content2[data-v-7a17b3ec] {
  width: 100%;
  margin: 0 auto;
  border: 3px solid #999;
  margin-top: 10px;
  position: relative;
}
.tops[data-v-7a17b3ec] {
  font-size: 0;
  position: relative;
  display: flex;
}
.tops .add[data-v-7a17b3ec] {
  position: absolute;
  right: -38px;
  top: 15px;
  width: 1.8rem;
  height: 1.8rem;
  vertical-align: -0.15rem;
  fill: currentColor;
  overflow: hidden;
  color: #f15a24;
  cursor: pointer;
}
.tops .add .svg-icon[data-v-7a17b3ec] {
  width: 100%;
  height: 100%;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.tops .gsbqw[data-v-7a17b3ec] {
  font-size: 0;
  border-top: 1px solid #f3f3f3;
  display: flex;
}
.tops .gsbqw div[data-v-7a17b3ec] {
  float: left;
  width: 11.3636363636%;
  height: 28px;
  font-size: 14px;
  vertical-align: top;
  border-left: 1px solid #f3f3f3;
}
.tops .gsbqw div[data-v-7a17b3ec]:nth-child(3) {
  border-color: #FFA9A9;
}
.tops .gsbqw div[data-v-7a17b3ec]:nth-child(6) {
  border-color: #FFD089;
}
.tops .gsbqw div[data-v-7a17b3ec]:nth-child(9) {
  border-color: #81ff89;
}
.tops .gsbqw div[data-v-7a17b3ec]:nth-child(12) {
  border-color: #9dd7ff;
}
.tops .gsbqw div[data-v-7a17b3ec]:nth-child(1) {
  border-left: none;
}
.tops .box_top[data-v-7a17b3ec] {
  width: 25%;
  border-left: 1px solid #666;
  border-bottom: 1px solid #666;
  display: inline-block;
  height: 60px;
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 15px;
  vertical-align: top;
  line-height: 60px;
  box-sizing: border-box;
}
.tops .box_top h6[data-v-7a17b3ec] {
  font-weight: 600;
  font-size: 17px;
  color: #333;
}
.tops .box_top > div[data-v-7a17b3ec] {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-weight: 500;
}
.tops .box_top[data-v-7a17b3ec]:nth-child(1) {
  border-left: none;
}
.bottom[data-v-7a17b3ec] {
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid #666;
  height: 82px;
  position: relative;
}
.bottom p[data-v-7a17b3ec] {
  font-size: 14px;
  text-align: center;
  line-height: 82px;
  font-weight: normal;
}
.bottom .add[data-v-7a17b3ec] {
  position: absolute;
  right: -38px;
  top: 8px;
  width: 1.8em;
  height: 1.8em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  color: #f15a24;
  cursor: pointer;
}
.bottom .add .svg-icon[data-v-7a17b3ec] {
  width: 100%;
  height: 100%;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.bottom .close[data-v-7a17b3ec] {
  width: 1.8em;
  height: 1.8em;
  vertical-align: -0.15em;
  fill: currentColor;
  position: absolute;
  right: -38px;
  top: 24px;
  font-size: 16px;
  color: #ff1818;
  cursor: pointer;
}
.bottom .close .svg-icon[data-v-7a17b3ec] {
  width: 100%;
  height: 100%;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.common_box[data-v-7a17b3ec] {
  float: left;
  height: 100%;
  width: 25%;
  border-left: 1px solid #666;
}
.common_box[data-v-7a17b3ec]:first-child {
  border-left: 0;
}
.infarm_box[data-v-7a17b3ec] {
  width: 100%;
  height: 82px;
  font-size: 14px;
  line-height: 22px;
  font-weight: normal;
  box-sizing: border-box;
  color: #333;
  border: none;
  margin: 0;
  padding: 5px;
}
.text_big[data-v-7a17b3ec] {
  position: relative;
  height: 82px;
}
.text_big li[data-v-7a17b3ec] {
  float: left;
  height: 82px;
  line-height: 82px;
  text-align: center;
  font-size: 14px;
  color: #333;
  vertical-align: top;
  border-left: 1px solid #f3f3f3;
  z-index: 8;
  position: relative;
  font-weight: bold;
}
.text_big li[data-v-7a17b3ec]:nth-child(1) {
  border-left: none;
}
.text_big input[data-v-7a17b3ec] {
  width: 100%;
  height: 100%;
  background: #fff;
  margin: 0 auto;
  z-index: 9999;
  box-shadow: 0 2px 10px #ddd;
  position: absolute;
  top: 0;
  left: 0;
}
.text_box li[data-v-7a17b3ec]:nth-child(3) {
  border-color: #f0ffb6;
}
.text_box li[data-v-7a17b3ec]:nth-child(6) {
  border-color: #ffedb0;
}
.text_box li[data-v-7a17b3ec]:nth-child(9) {
  border-color: #81ff89;
}
.text_box li[data-v-7a17b3ec]:nth-child(12) {
  border-color: #9dd7ff;
}
.text_box li[data-v-7a17b3ec] {
  padding: 3px;
}
.bottom_input[data-v-7a17b3ec] {
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
}
.bottom_input .item_btn[data-v-7a17b3ec] {
  width: 46%;
  margin: 0 auto;
  margin-bottom: 15px;
  position: relative;
}
.bottom_input .item_btn h6[data-v-7a17b3ec] {
  float: left;
  width: 80px;
  font-size: 15px;
  color: #333;
  font-weight: normal;
  line-height: 36px;
  margin-right: 10px;
  text-align: right;
}
.bottom_input .item_btn p[data-v-7a17b3ec] {
  float: left;
  line-height: 36px;
  color: #333;
  font-size: 15px;
  font-weight: normal;
}
.bottom_input .item_btn input[data-v-7a17b3ec] {
  float: left;
  width: 47%;
  border: none;
  border-bottom: 1px solid #666;
  margin-left: 5px;
  line-height: 22px;
}
.bottom_input .left_box[data-v-7a17b3ec] {
  width: 46%;
}
.bottom_input .right_box[data-v-7a17b3ec] {
  width: 46%;
}
.bottom_btn[data-v-7a17b3ec] {
  width: 1100px;
  margin: 0 auto;
  margin-top: 30px;
  text-align: right;
}
.form_style[data-v-2239e604] {
  position: absolute;
  top: 37px;
  right: 73px;
  width: 60%;
}
.el-dialog__footer[data-v-2239e604] {
  position: absolute;
  bottom: 14px;
  right: 13px;
}
.right[data-v-2239e604] {
  text-align: right;
  padding: 10px 10px 10px 10px;
}
.left[data-v-2239e604] {
  position: absolute;
  top: 110px;
  left: 214px;
}
.el-tree > .el-tree-node > .el-tree-node__content .el-checkbox[data-v-2239e604] {
  display: none;
}
.el-tree-node .is-leaf + .el-checkbox .el-checkbox__inner[data-v-2239e604] {
  display: inline-block;
}
.el-tree-node .el-checkbox .el-checkbox__inner[data-v-2239e604] {
  display: none;
}
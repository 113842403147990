[v-cloak][data-v-50d5740c] {
  display: none;
}
.tabs[data-v-50d5740c] {
  font-size: 14px;
  color: #657180;
  height: 100%;
}
.tabs .tabs-bar[data-v-50d5740c] {
  width: 30px;
  height: 100%;
  float: left;
  overflow-x: hidden;
  margin-top: 1px;
}
.tabs .tabs-content[data-v-50d5740c] {
  float: left;
  width: calc(100% - 30px);
  height: 100%;
}
.tabs-bar[data-v-50d5740c]:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: #d7dde4;
  margin-top: -1px;
}
.tabs-tab[data-v-50d5740c] {
  display: inline-block;
  padding: 15px 8px;
  background: #f7f9fc;
  cursor: pointer;
  position: relative;
  line-height: 20px;
  width: 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.tabs-tab .xian[data-v-50d5740c] {
  width: 41px;
  height: 1px;
  background: #efefef;
  position: absolute;
  bottom: -9px;
  left: 0;
  z-index: 3;
  transform: rotateZ(23deg);
}
.tabs-tab .sanjiaoxing[data-v-50d5740c] {
  border-top: 19px solid #f7f9fc;
  border-left: 44px solid transparent;
  position: relative;
  top: 16px;
  z-index: 2;
  left: -16px;
}
.tabs-tab .sanjiaoxingSpan[data-v-50d5740c] {
  border-top: 22px solid #f7f9fc;
  border-left: 44px solid transparent;
  position: relative;
  top: 46px;
  z-index: 2;
  left: -31px;
}
.tabs-tab-active[data-v-50d5740c] {
  color: white;
  background: var(--themeColor, #17a2b8);
  width: 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.tabs-tab-active .sanjiaoxing[data-v-50d5740c] {
  border-top: 19px solid var(--themeColor, #17a2b8);
  border-left: 44px solid transparent;
  position: relative;
  top: 16px;
  z-index: 2;
  left: -16px;
}
.tabs-tab-active .sanjiaoxingSpan[data-v-50d5740c] {
  border-top: 22px solid var(--themeColor, #17a2b8);
  border-left: 44px solid transparent;
  position: relative;
  top: 46px;
  z-index: 2;
  left: -31px;
}
[data-v-50d5740c]::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
@charset "UTF-8";
.box[data-v-42734c74] .el-timeline-item__content {
  font-size: 18px !important;
}
.box[data-v-42734c74] {
  height: 500px;
  overflow-y: overlay;
  overflow-x: hidden;
  scrollbar-width: none;
}
[data-v-42734c74]::-webkit-scrollbar {
  width: 0px;
}

/*滚动条里面小方块*/
[data-v-42734c74]::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(100, 100, 100, 0.2);
}
.active[data-v-42734c74] .el-timeline-item__node {
  background-color: var(--themeColor, #17a2b8);
}
[data-v-42734c74] textarea {
  min-height: 145px !important;
}
.bigimg[data-v-42734c74] {
  cursor: pointer;
  margin-top: 10px;
  position: relative;
}
.bigimg input[data-v-42734c74] {
  width: 98px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 35px;
}
.pic[data-v-42734c74] {
  height: 70px;
  border: 1px solid #eee;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
}
.del_btn[data-v-42734c74] {
  position: relative;
  top: 0;
  padding-right: 10px;
  color: red;
  cursor: pointer;
  left: -8px;
}
.opera_pic[data-v-42734c74] {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.icon[data-v-c9b9fd0e] {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 100000000000000000;
  display: block;
  color: #999;
  font-size: 30px;
  display: none;
  cursor: pointer;
}
.player:hover .icon[data-v-c9b9fd0e] {
  display: block;
}
.player[data-v-c9b9fd0e] {
  position: fixed;
  top: 44%;
  left: 37%;
  z-index: 100000000000000000;
  margin-left: -350px;
  margin-top: -200px;
}
.iconStyleVideo[data-v-c9b9fd0e] {
  position: relative;
  top: 3px;
  font-size: 15px;
  color: #67c23a;
  cursor: pointer;
}
.wordStyle[data-v-c9b9fd0e] {
  margin-right: 5px;
  position: relative;
  color: #67c23a;
  margin-left: 3px;
  cursor: pointer;
  font-size: 14px;
}
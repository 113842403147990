.ml-5 {
  margin-left: 5px !important;
}

.w-160 {
  width: 160px;
}

.w-200 {
  width: 200px !important;
}

.w-210 {
  width: 210px;
}

.w-220 {
  width: 220px;
}

.w-240 {
  width: 240px;
}

.row-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.w-700 {
  width: 700px;
}

.w-100 {
  width: 100px;
}

.w-120 {
  width: 120px;
}

.w-130 {
  width: 130px !important;
}

.w-140 {
  width: 140px !important;
}

.w-6b {
  width: 6%;
}

.w-160 {
  width: 160px;
}

.w-150 {
  width: 150px;
}

.w-170 {
  width: 170px;
}

.w-250 {
  width: 250px;
}

.mr-5 {
  margin-right: 5px;
}

.ml-0 {
  margin-left: 0;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.ml-10 {
  margin-left: 10px;
}

.m-rl-5 {
  margin: 0 5px;
}

.m-l0-r5 {
  margin-left: 0px;
  margin-right: 5px;
}

.m0-p5 {
  margin: 0;
  padding: 5px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.posi-rela {
  position: relative;
}

.ml-mr-0 {
  margin: 0;
}

.flex_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex_end {
  display: flex;
  justify-content: end;
  align-items: center;
}

.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex_align_center {
  display: flex;
  align-items: center;
}

.clickable_style {
  color: #409eff;
  cursor: pointer;
}

.mt-15 {
  margin-top: 15px;
}

.mt-10 {
  margin-top: 10px;
}

.red {
  color: red;
}

.mt-5 {
  margin-top: 5px;
}
.contentStyle {
  display: inline-block;
}
.contentStyle p img {
  max-width: 100%;
  max-height: 100%;
}
.new_dialog1 .el-dialog__header {
  background: #efefef;
  margin-right: 0px;
}
.new_dialog1 .el-dialog__body {
  padding-top: 0px;
}
@charset "UTF-8";
.iconfont[data-v-d60368d6] {
  font-size: 16px;
  margin-right: 10px;
}
.left-big[data-v-d60368d6] {
  height: 100%;
  background: #fff;
}
.left-big span[data-v-d60368d6] {
  font-size: 14px;
}

/*滚动条宽度*/
.left-big[data-v-d60368d6]::-webkit-scrollbar {
  width: 4px;
}

/*滚动条里面小方块*/
.left-big[data-v-d60368d6]::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(100, 100, 100, 0.2);
}

/*滚动条里面轨道*/
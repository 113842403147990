.help[data-v-97a94902] {
  color: #17a2b8;
  font-size: 13px;
  cursor: pointer;
  margin-left: 5px;
}
.help i[data-v-97a94902] {
  color: #17a2b8;
  font-size: 13px;
  margin-left: 3px;
}
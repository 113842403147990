.tag-name-copy[data-v-f3ff85ba] {
  display: flex;
  align-items: center;
}
.tag-name-copy .name-text[data-v-f3ff85ba] {
  flex: 1;
  width: 0;
}
.tag-name-copy .name-text .tag-item[data-v-f3ff85ba] {
  padding: 0 1px;
  margin-right: 5px;
}
.tag-name-copy .name-text .xgm[data-v-f3ff85ba] {
  border: 1px solid var(--themeColor, #17a2b8);
  background: var(--themeColorPlain);
  color: var(--themeColor, #17a2b8);
}
.tag-name-copy .name-text .yb[data-v-f3ff85ba] {
  border: 1px solid #ff7a00;
  background: #fef0f0;
  color: #ff7a00;
}
.tag-name-copy .copy-name[data-v-f3ff85ba] {
  color: var(--themeColor, #17a2b8);
  cursor: pointer;
}
.tag-name-copy .copy-name + div[data-v-f3ff85ba], .tag-name-copy .copy-name + i[data-v-f3ff85ba] {
  margin-left: 8px !important;
}
.margin0[data-v-06d17944] {
  margin: 0;
}
.w250[data-v-06d17944] {
  width: 250px;
}
.hint[data-v-06d17944] {
  color: var(--themeColor, #17a2b8);
  font-size: 14px;
  margin-bottom: 10px;
}
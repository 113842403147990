.tag[data-v-021586a7] {
  cursor: pointer;
}
.scroll-container[data-v-021586a7] {
  height: auto !important;
}
.tags-view-container .tags-view-wrapper .tags-view-item[data-v-021586a7]:first-of-type {
  text-align: left !important;
  margin-left: 0 !important;
  float: left;
}
.tags-view-container[data-v-021586a7] {
  width: 100%;
  margin: 0 auto;
  text-align: left !important;
  padding: 0 5px;
  margin-bottom: -5px;
  background-color: var(--themeColorBackground, #d9d9d9);
}
.tags-view-container .tags-view-wrapper .tag[data-v-021586a7] {
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 23px;
  line-height: 23px;
  border: 1px solid #e6e6e6;
  border-bottom: none;
  color: #495060;
  background: #ebf1f7;
  padding: 0 6px;
  font-size: 14px;
  margin-top: 4px;
}
.tags-view-container .tags-view-wrapper .tag[data-v-021586a7]:first-of-type {
  margin-left: 0 !important;
}
.tags-view-container .tags-view-wrapper .tag[data-v-021586a7]:last-of-type {
  margin-right: 15px;
}
.tags-view-container .tags-view-wrapper .tag.cur[data-v-021586a7] {
  background-color: var(--themeColor, #17a2b8);
  color: #fff;
  border-color: var(--themeColor, #17a2b8);
}
.tags-view-container .tags-view-wrapper .tag.cur[data-v-021586a7]::before {
  content: "";
  background: #fff;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: relative;
  margin-right: 2px;
}
.tags-view-container .contextmenu[data-v-021586a7] {
  margin: 0;
  background: #fff;
  z-index: 2147483647;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
}
.tags-view-container .contextmenu li[data-v-021586a7] {
  margin: 0;
  padding: 7px 16px;
  cursor: pointer;
}
.tags-view-container .contextmenu li[data-v-021586a7]:hover {
  background: #eee;
}
.cur[data-v-021586a7] {
  background-color: var(--themeColor, #17a2b8);
  color: #fff;
  border-color: var(--themeColor, #17a2b8);
}
.cur[data-v-021586a7]::before {
  content: "";
  background: #fff;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: relative;
  margin-right: 2px;
}
.el-tag[data-v-021586a7] .el-tag__close {
  color: #b5b6bd !important;
}
.el-tag[data-v-021586a7] .el-tag__close:hover {
  background-color: #d2e1ef;
}
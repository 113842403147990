.zz[data-v-19c2e88f] {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 999;
}
.big[data-v-19c2e88f] {
  position: relative;
}
.content[data-v-19c2e88f] {
  padding: 10px;
  min-width: 300px;
  min-height: 200px;
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 1000;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #e4e7ed;
}
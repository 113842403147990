.top[data-v-0d462c9e] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.w120[data-v-0d462c9e] {
  width: 120px;
}
.item_icon[data-v-0d462c9e] {
  cursor: pointer;
}
.item_icon .iconfont[data-v-0d462c9e] {
  font-size: 13px;
}
.login-tax-bureau-opera-get-auth-link li[data-v-0d462c9e] {
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.login-tax-bureau-opera-get-auth-link li span[data-v-0d462c9e] {
  margin-left: 5px;
}
.login-tax-bureau-opera-get-auth-link li[data-v-0d462c9e]:hover {
  color: #17a2b8;
}
.foot[data-v-0d462c9e] {
  margin: 10px 0;
}
.foot > div[data-v-0d462c9e] {
  float: right;
}
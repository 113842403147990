.one_center[data-v-b2bf4bfe] {
  text-align: center;
  position: relative;
  border-radius: 4px;
  padding: 2px 5px;
  background: #fff6ed;
  border: 0.6px solid #ff8d1a;
  color: #ff8d1a;
  font-size: 13px;
}
.one_center .right_per[data-v-b2bf4bfe] {
  position: absolute;
  top: 2px;
  right: 10px;
}
.pink[data-v-b2bf4bfe] {
  background: #feecef;
  border: 0.6px solid #fa6e83;
  color: #fa6e83;
}
.null_data[data-v-b2bf4bfe] {
  background: #f7f7f7;
  border: 0.6px solid #A7A7A7;
  color: #A7A7A7;
}
.icon-gantanhao[data-v-b2bf4bfe] {
  font-size: 13px;
}
.content[data-v-307c2182] {
  display: flex;
  align-items: center;
  margin: 15px 0;
  position: relative;
  padding-left: 40px;
}
.content .pic[data-v-307c2182] {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  position: absolute;
  left: 15px;
}
.content span[data-v-307c2182] {
  cursor: pointer;
  color: #17a2b8;
  margin-right: 5px;
}